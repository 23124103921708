<template>
  <div class="z-20 dropdown dropdown-end">
    <div tabindex="0" class="m-1">
      <img src="/icons/dots.svg" alt="">
    </div>
    <ul tabindex="0" class="rounded-lg shadow menu dropdown-content bg-base-300 w-72">
      <li>
        <a>
          <img class="mr-3" src="/icons/access.svg" alt="">
          Access
        </a>
      </li>
      <li>
        <a>
          <img class="mr-3" src="/icons/remove.svg" alt="">
          Remove
        </a>
      </li>
      <li>
        <a>
          <img class="mr-3" src="/icons/add.svg" alt="">
          Add
        </a>
      </li>
      <li>
        <a>
          <img class="mr-3" src="/icons/bookmark.svg" alt="">
          Bookmark
        </a>
      </li>
      <li>
        <a>
          <img class="mr-3" src="/icons/delete.svg" alt="">
          <span class="text-error">Delete</span>
        </a>
      </li>
    </ul>
  </div>
</template>
