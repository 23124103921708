<template>
  <div style="padding-top: 25vh">
    <ProfilerSearch />
  </div>
</template>

<script>
import ProfilerSearch from '@/components/profiler-search/Index.vue'

export default {
  components: {
    ProfilerSearch,
  },
}
</script>
