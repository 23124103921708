<template>
  <div class="px-10 py-4 overflow-y-scroll pt-10 bg-base-200">
    <div class="grid grid-cols-2 gap-2 mt-3">
      <div class="form-control">
        <label class="label">
          <span class="label-text">Name</span>
        </label>
        <input type="text" class="input bg-base-200 input-bordered" value="this is my case title">
      </div>
      <div class="form-control">
        <label class="label">
          <span class="label-text">Group Type</span>
        </label>
        <select name="daterage" class="select bg-base-300">
          <option value="">- Select -</option>
        </select>
      </div>
    </div>
  </div>
</template>
<script>
</script>
