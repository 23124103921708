<template>
  <div style="padding-top: 25vh">
    <GlobalSearch />
  </div>
</template>

<script>
import GlobalSearch from '@/components/global-search/Index.vue'

export default {
  components: {
    GlobalSearch,
  },
}
</script>
