<template>
  <table class="table w-full" style="border: 1px solid #292932;">
    <tr class="bg-base-300">
      <td colspan="2" class="profiler-profile--table-title">Personal Details</td>
    </tr>
    <tr style="background: #23232B;">
      <td colspan="2" class="profiler-profile--table-subtitle">
        Numbers
      </td>
    </tr>
    <tr>
      <td class="profiler-profile--table-left">Phone</td>
      <td class="profiler-profile--table-right">+1 (541) 547 547</td>
    </tr>
    <tr>
      <td class="profiler-profile--table-left">Phone</td>
      <td class="profiler-profile--table-right">+1 (541) 547 547</td>
    </tr>
    <tr>
      <td class="profiler-profile--table-left">Phone</td>
      <td class="profiler-profile--table-right">+1 (541) 547 547</td>
    </tr>
    <tr style="background: #23232B;">
      <td colspan="2" class="profiler-profile--table-subtitle">
        Locations
      </td>
    </tr>
    <tr>
      <td class="profiler-profile--table-left">City</td>
      <td class="profiler-profile--table-right">Boston</td>
    </tr>
    <tr>
      <td class="profiler-profile--table-left">Country</td>
      <td class="profiler-profile--table-right">United States</td>
    </tr>
    <tr>
      <td class="profiler-profile--table-left">Home town</td>
      <td class="profiler-profile--table-right">Side town</td>
    </tr>
    <tr style="background: #23232B;">
      <td colspan="2">
        Social Media Pages
      </td>
    </tr>
    <tr>
      <td class="profiler-profile--table-left">Facebook</td>
      <td class="profiler-profile--table-right">Link</td>
    </tr>
    <tr>
      <td class="profiler-profile--table-left">Twitter</td>
      <td class="profiler-profile--table-right">Link</td>
    </tr>
    <tr>
      <td class="profiler-profile--table-left">Instagram</td>
      <td class="profiler-profile--table-right">Link</td>
    </tr>
  </table>
</template>
