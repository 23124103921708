<template>
  <div class="absolute dropdown dropdown-end right-1">
    <div tabindex="0"><img src="/icons/dots.svg" alt=""></div>
    <ul tabindex="0" class="p-2 rounded-lg shadow w-286 menu dropdown-content bg-base-300">
      <li>
        <a>
          <img class="mr-3" src="/icons/bookmark.svg" alt="">
          Bookmark
        </a>
      </li>
      <li>
        <a>
          <img class="mr-3" src="/icons/delete-active.svg" alt="">
          Delete
        </a>
      </li>
    </ul>
  </div>
</template>
