<template>
  <table class="table w-full" style="border: 1px solid #292932;">
    <tr class="bg-base-300">
      <td colspan="2" class="profiler-profile--table-title">Additional Info</td>
    </tr>
    <tr>
      <td class="profiler-profile--table-left">Full name</td>
      <td class="profiler-profile--table-right">Lebron James</td>
    </tr>
    <tr>
      <td class="profiler-profile--table-left">National identity</td>
      <td class="profiler-profile--table-right">USA</td>
    </tr>
    <tr>
      <td class="profiler-profile--table-left">Image path</td>
      <td class="profiler-profile--table-right">www.apnews.com</td>
    </tr>
    <tr>
      <td class="profiler-profile--table-left">Phone number</td>
      <td class="profiler-profile--table-right">+1 215 215 321</td>
    </tr>
    <tr>
      <td class="profiler-profile--table-left">Driver license</td>
      <td class="profiler-profile--table-right">GT5487</td>
    </tr>
    <tr>
      <td class="profiler-profile--table-left">Email address</td>
      <td class="profiler-profile--table-right">James@email.com</td>
    </tr>
  </table>
</template>
