<template>
  <div class="relative px-10 pt-10">
    <h5>Connection Information</h5>
    <div class="grid grid-cols-2 gap-3">
      <div class="form-control">
        <label class="label">
          <span class="label-text">Proxy Type</span>
        </label>
        <select name="country" class="select bg-base-300">
          <option value="">-- Select --</option>
          <option value="jpn">Jepang</option>
        </select>
      </div>
      <div class="form-control">
        <label class="label">
          <span class="label-text">Proxy</span>
        </label>
        <select name="country" class="select bg-base-300">
          <option value="">-- Select --</option>
          <option value="jpn">Jepang</option>
        </select>
      </div>
      <div class="form-control">
        <h3>User Agent Details</h3>
        <label class="label">
          <span class="label-text">Proxy</span>
        </label>
        <select name="country" class="select bg-base-300">
          <option value="">-- Select --</option>
          <option value="jpn">Jepang</option>
        </select>
      </div>
    </div>
  </div>
</template>
