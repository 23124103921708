<template>
  <div class="flex-grow mb-5 space-y-5">
    <div class="flex justify-between">
      <div></div>
      <div class="btn-group">
        <button class="btn btn-sm">
          <img src="/icons/translate.svg" alt="">
        </button>
        <button class="btn btn-sm">
          <img src="/icons/copy.svg" alt="">
        </button>
        <button class="btn btn-sm">
          <img src="/icons/notes.svg" alt="">
        </button>
        <button class="btn btn-sm">
          <img src="/icons/align.svg" alt="">
        </button>
        <button class="btn btn-sm">
          <img src="/icons/person-location.svg" alt="">
        </button>
        <button class="btn btn-sm">
          <img src="/icons/person-alt.svg" alt="">
        </button>
        <button class="btn btn-sm text-error">
          <img src="/icons/trash.svg" alt="">
        </button>
      </div>
    </div>
    <div class="flex-row p-5 space-y-5 divide-y divide-gray-600 rounded-lg bg-base-200">
      <div class="px-3 py-1">
        <h3 class="text-agatha-400">Title</h3>
        <h3>Former CIA Agent Edward Snowden Set To Speak To Students At Iowa State</h3>
      </div>
      <div class="p-3">
        <h3 class="text-agatha-400">Description</h3>
        <h3>AMES, Iowa — Edward Snowden, who took the international spotlight when he revealed classified government information in 2013, will speak to students at Iowa State University on March 4 live on YouTube. Snowden will be speaking from Moscow. Snowden is set to present a talk called "Digital Surveillance: How Gen Z Gave Up Its Privacy to Corporations and the Government" on Thursday night.
          Snowden, who is now the president of the board of directors of the Freedom of the Press Foundation, is the author of Permanent Record.</h3>
      </div>
      <div class="px-3 py-1">
        <h3 class="text-agatha-400">Tags</h3>
        <div class="mt-3 space-x-2">
          <div class="inline-block px-2 text-sm rounded-box bg-base-300">Lindsay Mills</div>
          <div class="inline-block px-2 text-sm rounded-box bg-base-300">Lindsay Mills</div>
          <div class="inline-block px-2 text-sm rounded-box bg-base-300">Lindsay Mills</div>
        </div>
      </div>
      <div class="px-3 py-1">
        <h3 class="text-agatha-400">Places</h3>
        <div class="mt-3 space-x-2">
          <div class="inline-block px-2 text-sm rounded-box bg-primary">South East Asia</div>
          <div class="inline-block px-2 text-sm rounded-box bg-primary">Beijing</div>
          <div class="inline-block px-2 text-sm rounded-box bg-primary">Singapore</div>
          <div class="inline-block px-2 text-sm rounded-box bg-primary">Hongkong</div>
          <div class="inline-block px-2 text-sm rounded-box bg-primary">Shangai</div>
          <div class="inline-block px-2 text-sm rounded-box bg-primary">Myanmar</div>
          <div class="inline-block px-2 text-sm rounded-box bg-primary">Cambodia</div>
        </div>
      </div>
      <div class="px-3 py-1">
        <h3 class="text-agatha-400">Places</h3>
        <div class="mt-3 space-x-2">
          <div class="inline-block px-2 text-sm rounded-box bg-error">Philippenes Ariways</div>
          <div class="inline-block px-2 text-sm rounded-box bg-error">Thai smile air</div>
          <div class="inline-block px-2 text-sm rounded-box bg-error">Macau</div>
        </div>
      </div>
      <div class="px-3 py-1">
        <h3 class="text-agatha-400">Original Properties</h3>
        <table class="w-full mt-3 text-sm table-compact">
          <tr>
            <td class="text-success">Title</td>
            <td>Kevin Scot Facebook Post</td>
          </tr>
          <tr>
            <td class="text-success">Source Url</td>
            <td><a class="text-info" target="_blank" href="https://www.facebook.com/muftimenk/posts/302636721231978">https://www.facebook.com/muftimenk/posts/302636721231978</a></td>
          </tr>
          <tr>
            <td class="text-success">Content Url</td>
            <td><a class="text-info" target="_blank" href="https://www.weareiowa.com/article/news/local/fugitive-and-former-cia-agent">https://www.weareiowa.com/article/news/local/fugitive-and-former-cia-agent</a></td>
          </tr>
          <tr>
            <td class="text-success">Publish Date</td>
            <td>2021/12/02</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
