<template>
  <table class="table w-full mb-5" style="border: 1px solid #292932;">
    <tr class="bg-base-300">
      <td class="profiler-profile--table-title">Infiltrated Media</td>
    </tr>
    <tr>
      <td>
        <div class="flex">
          <img src="https://picsum.photos/88" class="profiler-profile--img mr-5" alt="" />
          <img src="https://picsum.photos/88" class="profiler-profile--img mr-5" alt="" />
          <img src="https://picsum.photos/88" class="profiler-profile--img mr-5" alt="" />
          <img src="https://picsum.photos/88" class="profiler-profile--img mr-5" alt="" />
          <img src="https://picsum.photos/88" class="profiler-profile--img mr-5" alt="" />
          <img src="https://picsum.photos/88" class="profiler-profile--img" alt="" />
        </div>
      </td>
    </tr>
  </table>
</template>
