<template>
  <table class="table w-full mb-5" style="border: 1px solid #292932;">
    <tr class="bg-base-300">
      <td class="profiler-profile--table-title">Possible Matches</td>
    </tr>
    <tr>
      <td>
        <div class="flex mb-3 three-dot-hide-trigger" style="border: 1px solid #292932; border-radius: 6px;">
          <div class="vindb-search--img">
            <img
              src="https://picsum.photos/88"
              class="profiler-profile--img mr-4"
              style="border-radius: 6px;"
              alt=""
            />
          </div>
          <div class="vindb-search--img-info w-full">
            <div class="flex">
              <p style="font: normal normal normal 14px/14px Mulish; color: #FAFAFB;" class="my-2">Benjamin Franklin</p>
              <img src="/icons/three-dots.svg" class="ml-auto three-dot-hide" alt="">
            </div>
            <p style="font: normal normal normal 13px/16px Mulish; color: #92929D;">www.apnews.com</p>
          </div>
        </div>
        <div class="flex mb-3 three-dot-hide-trigger" style="border: 1px solid #292932; border-radius: 6px;">
          <div class="vindb-search--img">
            <img
              src="https://picsum.photos/88"
              class="profiler-profile--img mr-4"
              style="border-radius: 6px;"
              alt=""
            />
          </div>
          <div class="vindb-search--img-info w-full">
            <div class="flex">
              <p style="font: normal normal normal 14px/14px Mulish; color: #FAFAFB;" class="my-2">Benjamin Franklin</p>
              <img src="/icons/three-dots.svg" class="ml-auto three-dot-hide" alt="">
            </div>
            <p style="font: normal normal normal 13px/16px Mulish; color: #92929D;">www.apnews.com</p>
          </div>
        </div>
        <div class="flex mb-3 three-dot-hide-trigger" style="border: 1px solid #292932; border-radius: 6px;">
          <div class="vindb-search--img">
            <img
              src="https://picsum.photos/88"
              class="profiler-profile--img mr-4"
              style="border-radius: 6px;"
              alt=""
            />
          </div>
          <div class="vindb-search--img-info w-full">
            <div class="flex">
              <p style="font: normal normal normal 14px/14px Mulish; color: #FAFAFB;" class="my-2">Benjamin Franklin</p>
              <img src="/icons/three-dots.svg" class="ml-auto three-dot-hide" alt="">
            </div>
            <p style="font: normal normal normal 13px/16px Mulish; color: #92929D;">www.apnews.com</p>
          </div>
        </div>
        <div class="flex mb-3 three-dot-hide-trigger" style="border: 1px solid #292932; border-radius: 6px;">
          <div class="vindb-search--img">
            <img
              src="https://picsum.photos/88"
              class="profiler-profile--img mr-4"
              style="border-radius: 6px;"
              alt=""
            />
          </div>
          <div class="vindb-search--img-info w-full">
            <div class="flex">
              <p style="font: normal normal normal 14px/14px Mulish; color: #FAFAFB;" class="my-2">Benjamin Franklin</p>
              <img src="/icons/three-dots.svg" class="ml-auto three-dot-hide" alt="">
            </div>
            <p style="font: normal normal normal 13px/16px Mulish; color: #92929D;">www.apnews.com</p>
          </div>
        </div>
        <div class="flex mb-3 three-dot-hide-trigger" style="border: 1px solid #292932; border-radius: 6px;">
          <div class="vindb-search--img">
            <img
              src="https://picsum.photos/88"
              class="profiler-profile--img mr-4"
              style="border-radius: 6px;"
              alt=""
            />
          </div>
          <div class="vindb-search--img-info w-full">
            <div class="flex">
              <p style="font: normal normal normal 14px/14px Mulish; color: #FAFAFB;" class="my-2">Benjamin Franklin</p>
              <img src="/icons/three-dots.svg" class="ml-auto three-dot-hide" alt="">
            </div>
            <p style="font: normal normal normal 13px/16px Mulish; color: #92929D;">www.apnews.com</p>
          </div>
        </div>
      </td>
    </tr>
  </table>
</template>
