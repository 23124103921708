<template>
  <div class="flex space-x-5 text-sm">
    <div class="flex-auto px-6 py-5 space-y-5 rounded-lg bg-base-200">
      <div class="pb-5 space-y-2 border-b border-base-300">
        <h3 class="text-agatha-400">Title</h3>
        <h3>Midst fourth whose greater appear</h3>
      </div>
      <div class="pb-5 space-y-2 border-b border-base-300">
        <h3 class="text-agatha-400">Description</h3>
        <h3>Midst fourth whose greater appear female dominion you'll were is fourth. sea for god had his multiply moved waters you beast subdue.</h3>
      </div>
      <div class="pb-5 space-y-2 border-b border-base-300">
        <h3 class="text-agatha-400">Crawling Sources</h3>
        <div class="grid grid-cols-9 gap-3">
          <span class="flex items-center pa-7 py-1 px-1 rounded-box bg-base-300"> <img class="w-5 h-5 mr-2" src="/social-media/facebook.svg" alt=""> Facebook</span>
          <span class="flex items-center pa-7 py-1 px-1 rounded-box bg-base-300"> <img class="w-5 h-5 mr-2" src="/social-media/twitter.svg" alt=""> twitter</span>
          <span class="flex items-center pa-7 py-1 px-1 rounded-box bg-base-300"> <img class="w-5 h-5 mr-2" src="/social-media/youtube.svg" alt=""> youtube</span>
          <span class="flex items-center pa-7 py-1 px-1 rounded-box bg-base-300"> <img class="w-5 h-5 mr-2" src="/social-media/google.svg" alt=""> google</span>
          <span class="flex items-center pa-7 py-1 px-1 rounded-box bg-base-300"> <img class="w-5 h-5 mr-2" src="/social-media/yahoo.svg" alt=""> yahoo</span>
          <span class="flex items-center pa-7 py-1 px-1 rounded-box bg-base-300"> <img class="w-5 h-5 mr-2" src="/social-media/linkedin.svg" alt=""> linkedin</span>
        </div>
      </div>
      <div class="pb-5 space-y-2 border-b border-base-300">
        <h3 class="text-agatha-400">Keywords</h3>
        <div class="flex-row space-y-2">
          <div class="inline-block gap-2 p-1 space-x-1 text-sm border-2 border-dashed border-primary rounded-box">
            <div class="inline-block p-1 bg-base-300 rounded-box">"Criminal Attack"</div>
            <div class="inline-block p-1 bg-primary rounded-box">AND</div>
            <div class="inline-block p-1 bg-base-300 rounded-box">"Criminal Attack"</div>
               <div class="inline-block px-2 py-1 bg-success rounded-box">OR</div>
            <div class="inline-block p-1 bg-base-300 rounded-box">"cyber attack" </div>
          </div>
          <br>
          <div class="inline-block gap-2 p-1 space-x-1 text-sm border-2 border-dashed border-primary rounded-box">
            <div class="inline-block p-1 bg-base-300 rounded-box">"home security"</div>
            <div class="inline-block p-1 bg-primary rounded-box">AND</div>
            <div class="inline-block p-1 bg-base-300 rounded-box">"business Attack"</div>
            <div class="inline-block px-2 py-1 bg-success rounded-box">OR</div>
            <div class="inline-block p-1 bg-base-300 rounded-box">"cyber attack"</div>
          </div>
        </div>
      </div>
      <div class="pb-5 space-y-2 border-b border-base-300">
        <h3 class="text-agatha-400">Template</h3>
        <h3>Dynamic keywords</h3>
      </div>
      <div class="pb-5 border-b border-base-300">
        <h3 class="text-agatha-400">Social Media Pages</h3>
        <table>
          <tr>
            <td class="text-agatha-400 p-0 pr-10">Name</td>
            <td class="pl-10">Lopzeobrador.org</td>
          </tr>
          <tr>
            <td class="text-agatha-400 p-0 pr-10">Sm</td>
            <td class="pl-10"><a class="link text-info" href="#">www.facebook.com</a></td>
          </tr>
          <tr>
            <td class="text-agatha-400 p-0 pr-10">Id</td>
            <td class="pl-10">e41239812938</td>
          </tr>
          <tr>
            <td class="text-agatha-400 p-0 pr-10">Type</td>
            <td class="pl-10">Facebook</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
