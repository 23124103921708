<template>
  <table class="table w-full" style="border: 1px solid #292932;">
    <tr class="bg-base-300">
      <td colspan="2" class="profiler-profile--table-title">Additional Info</td>
    </tr>
    <tr>
      <td class="profiler-profile--table-left">Manufactured by</td>
      <td class="profiler-profile--table-right">BENTLEY MOTORS LIMITED</td>
    </tr>
    <tr>
      <td class="profiler-profile--table-left">Plant Company Name</td>
      <td class="profiler-profile--table-right">BENTLEY INC</td>
    </tr>
    <tr>
      <td class="profiler-profile--table-left">Vehicle Type</td>
      <td class="profiler-profile--table-right">PASSENGER CAR</td>
    </tr>
    <tr>
      <td class="profiler-profile--table-left">Series</td>
      <td class="profiler-profile--table-right">Continental</td>
    </tr>
    <tr>
      <td class="profiler-profile--table-left">Body Class</td>
      <td class="profiler-profile--table-right">Coupe</td>
    </tr>
    <tr>
      <td class="profiler-profile--table-left">Doors</td>
      <td class="profiler-profile--table-right">2</td>
    </tr>
    <tr>
      <td class="profiler-profile--table-left">Front Airbag Location</td>
      <td class="profiler-profile--table-right">1st Row (Driver and Passenger)</td>
    </tr>
    <tr>
      <td class="profiler-profile--table-left">Seat Belts Type</td>
      <td class="profiler-profile--table-right">Manual</td>
    </tr>
    <tr>
      <td class="profiler-profile--table-left">Engine Displacement (CI)</td>
      <td class="profiler-profile--table-right">366.1424645683</td>
    </tr>
    <tr>
      <td class="profiler-profile--table-left">Engine Displacement (CC)</td>
      <td class="profiler-profile--table-right">6000</td>
    </tr>
    <tr>
      <td class="profiler-profile--table-left">Fuel Type</td>
      <td class="profiler-profile--table-right">Flexibel Fuel Vehicle (FFV)</td>
    </tr>
    <tr>
      <td class="profiler-profile--table-left">Engine Numbers of Cylinders</td>
      <td class="profiler-profile--table-right">12</td>
    </tr>
  </table>
</template>
