<template>
  <table class="table w-full mb-5" style="border: 1px solid #292932;">
    <tr class="bg-base-300">
      <td class="profiler-profile--table-title">Vehicle Info</td>
    </tr>
    <tr>
      <td>
        <div class="flex">
          <div class="vindb-search--img">
            <img
              src="https://picsum.photos/88"
              class="profiler-profile--img mr-4"
              alt=""
            />
          </div>
          <div class="vindb-search--img-info">
            <p style="color: #FAFAFB; font: normal normal bold 16px/14px Mulish; margin-bottom: 5px;">BENTLEY CONTINENTAL</p>
            <p><span style="color: #92929D; font: normal normal normal 14px/14px Mulish;">Year:</span> 2012</p>
            <p><span style="color: #92929D; font: normal normal normal 14px/14px Mulish;">Make:</span> Bentley</p>
            <p><span style="color: #92929D; font: normal normal normal 14px/14px Mulish;">Manufactured in:</span> France</p>
          </div>
          <img src="/icons/three-dots.svg" class="ml-auto mb-auto" alt="">
        </div>
      </td>
    </tr>
  </table>
</template>
