<template>
  <div class="px-10 py-4 overflow-y-scroll pt-10 bg-base-200">
    <h5>Meta Data</h5>
    <div class="grid grid-cols-2 gap-2 mt-3">
      <div class="form-control">
        <label class="label">
          <span class="label-text">Title</span>
        </label>
        <input type="text" class="input bg-base-300" value="this is my case title">
      </div>
      <div class="form-control">
        <label class="label">
          <span class="label-text">Documents Published Date Range</span>
        </label>
        <select name="daterage" class="select bg-base-300">
          <option value="">- Select date range -</option>
        </select>
      </div>
      <div class="col-span-2 form-control">
        <label class="label">
          <span class="label-text">Username</span>
        </label>
        <textarea name="description" class="w-full textarea bg-base-300" rows="3">Midst Fourth Whose Greater Appear Female Dominion You'll Were Is Fourth. Sea For God Had His Multiply Moved Waters You Beast Subdue. </textarea>
      </div>
    </div>
  </div>
</template>
