<template>
  <div class="flex-none hidden rounded-t-lg w-72 md:block bg-base-200">
    <div class="px-8 py-4 border-b-2 border-base-300">
      <h3 class="text-sm font-bold">Selected Entity</h3>
    </div>
    <div class="px-8 py-4 space-y-5">
      <div class="h-40 text-center bg-base-300">MAP</div>
      <div class="flex-row divide-y divide-gray-600">
        <div class="py-4">
          <h3 class="text-sm text-agatha-400">Address</h3>
          <p class="text-sm">New York, Georgia, Atlanta, United States</p>
        </div>
        <div class="py-4">
          <h3 class="text-sm text-agatha-400">Area Education</h3>
          <p class="text-sm">BS from Medgar Evers College - City University of New York</p>
        </div>
        <div class="py-4">
          <h3 class="text-sm text-agatha-400">Organizations</h3>
          <div class="">
            <div class="inline-block px-2 text-sm bg-base-300 rounded-box">Cbre</div>
            <div class="inline-block px-2 text-sm bg-base-300 rounded-box">Verdom GRoup</div>
            <div class="inline-block px-2 text-sm bg-base-300 rounded-box">Macmillan</div>
          </div>
        </div>
        <div class="py-4">
          <h3 class="text-sm text-agatha-400">Country</h3>
          <p class="text-sm">United States</p>
        </div>
        <div class="py-4">
          <h3 class="text-sm text-agatha-400">Social Media Pages</h3>
          <table class="w-full text-sm">
            <tr>
              <td class="text-agatha-400">Name</td>
              <td>lapzonr.org</td>
            </tr>
            <tr>
              <td class="text-agatha-400">Sm</td>
              <td><a class="link text-info">www.facebook.com</a></td>
            </tr>
            <tr>
              <td class="text-agatha-400">Id</td>
              <td>ef019231</td>
            </tr>
            <tr>
              <td class="text-agatha-400">Type</td>
              <td>Facebook</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
